<template>
  <v-card
    :class="getContainerClass"
    class="transparent"
    flat
  >
    <v-card
      :class="getCardClass"
      class="transparent"
      flat
    >
      <v-card
        v-if="isConfigMode"
        flat
        class="transparent"
        min-width="100px"
      >
        <configurationButtons
          :viewconfig="getViewConfig"
          :type="getConfigType"
          color="transparent"
        />
      </v-card>
      <v-container
        ref="myCardContainer"
        :class="getCardClass"
        fluid
        grid-list-md
      >
        <resize-observer @notify="onResizeComponent" />
        <v-row
          justify-space-between
          no-gutters
        >
          <v-col
            v-for="(card, index) in getCards"
            :key="card.cardConfig"
            :cols="calculateWidth(card)"
            :class="getDynamicCardClass(index)"
            align="stretch"
          >
            <!-- <v-col style="height: 100%"> -->
            <v-hover
              v-slot="{ hover }"
              :disabled="!isConfigMode"
            >
              <v-card
                :elevation="hover ? 6 : 2"
                :style="{ 'height': getHeightOfCard(card) }"
                class="no-scroll mx-0 pa-0 rounded-lg card "
              >
                <v-expand-transition>
                  <div
                    v-if="hover"
                    :color="getBarColor"
                    class="d-flex transition-fast-in-fast-out orange darken-2 v-card--reveal text-h2 white--text"
                    style="height: 50px;"
                  >
                    <configurationButtons
                      :color="getBarColor"
                      :viewconfig="card"
                      :parentconfig="card"
                      :type="getConfigType"
                    />
                  </div>
                </v-expand-transition>

                <!--<v-card 
                  height="25px" 
                  flat 
                  class="ma-0 pt-3 pr-4 text-right">
                  <v-dialog 
                    v-model="dialog" 
                    width="80%"
                  ><template v-slot:activator="{ on, attrs }">
                    <v-btn 
                      v-bind="attrs" 
                      icon 
                      light 
                      dense 
                      v-on="on">
                      <v-icon class="configLight">mdi-window-maximize</v-icon>
                    </v-btn>
                  </template>
<v-card class="ma-0 pa-4">
  <viewConfigView :ref="card.subViewConfig" :object="getObject" :viewconfig="card.subViewConfig" :editmode="editmode"
    :search="search" :in-card="true" height="1500px" />
</v-card>
</v-dialog>
</v-card>-->

                <viewConfigView
                  :height="getHeightOfCardContent(card)"
                  :object="getObject"
                  :viewconfig="card.subViewConfig"
                  :editmode="editmode"
                  :search="search"
                  :in-card="true"
                  :isCurrentTab="isCurrentTab"
                />

              </v-card>
            </v-hover>
            <!-- </v-col> -->
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-card>
</template>

<script>
import { isNumeric } from '../../api/utilities.js';
// import configurationView from "./ConfigurationView";
import * as Constants from "../../store/constants.js";
export default {
  components: {},
  props: {
    viewconfig: {
      type: String,
      default: ""
    },
    editmode: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: "300px"
    },
    object: {
      type: Object,
      default: null
    },
    search: {
      type: String,
      default: ""
    },
    isCurrentTab: {
      type: Boolean,
      default: ""
    },
  },
  data() {
    return {
      hover: false,
      myContainerWidth: 1500,
      dialog: false
    };
  },
  computed: {
    /**************************
      Configuration
    **************************/
    getContainerClass() {
      if (this.$store.getters.getDeviceSize === 1) {
        return "scroll-y smoothscroll pa-0 ma-0";
      }
      return "scroll-y smoothscroll py-0 px-0 ma-0 ";
    },
    getCardClass() {
      /*
      if (this.isConfigMode === true) {
        return " no-scroll ma-0 pa-2 elevation-10 ";
      }
      */
      return " ma-0 pa-0 pb-2 elevation-0 ";
    },
    isConfigMode() {
      return this.$store.getters.isConfigMode(this.getViewConfig);
    },
    getConfigType() {
      return Constants.CARD_CONFIG;
    },
    getCards() {

      var cards = this.$store.getters.getCardConfigsForViewConfig(
        this.getViewConfig.subViewConfig
      );
      if (this.isConfigMode !== true) {
        cards = cards.filter(function (o) {
          return o.isHidden !== true;
        });
      }
      return cards
    },
    getViewConfig() {
      return this.$store.getters[Constants.GET_VIEW_CONFIG_FOR_ID](
        this.viewconfig
      );
    },
    isSmallSize() {
      return this.myContainerWidth < Constants.MEDIUM_SIZE_THRESHOLD; //500
    },
    getObject() {
      return this.object;
    },

    getBarColor() {
      return "orange darken-2";
    }
  },
  mounted: function () {
    this.$nextTick(() => {
      this.onResizeComponent();
    });
  },
  methods: {
    getDynamicCardClass(index) {

      if (this.isSmallSize) {
        if (index === 0) {
          return "py-2 pt-4 px-3";
        } else if (index === this.getCards.length - 1) {
          return "py-2 pb-16 px-3";
        } else return "py-2 px-3";
      }
      else {
        return "py-3 px-3"; //ma-3 pa-3
      }
    },
    getHeight(card) {
      // if (this.isConfigMode === true && card.height > 1) {
      //   return isNumeric(card.height) ? card.height + 100 : Number(card.height) + 100;
      // }
      if (card.height > 1) {
        return card.height;
      }
      else {
        return undefined;
      }
    },
    getHeightOfCard(card) {
      var height = this.getHeight(card)
      if (height === undefined || height === null || height === 0) {
        return '100%'
      }
      return "".concat(height).concat("px");
    },
    getHeightOfCardContent(card) {
      var height = this.getHeight(card) - 50;
      return "".concat(height).concat("px");
    },
    getClassOfCard(card) {
      var classe = "xs".concat(this.calculateWidth(card));
      return classe;
    },
    calculateWidth(card) {
      var width = this.myContainerWidth;
      var thresholdXs = 600;
      var thresholdSm = 900;
      // var thresholdLg = 1200;
      var value = card.flex;
      if (width < thresholdXs) {
        value = 12;
      } else if (width < thresholdSm) {
        if (card.flex > 6) {
          value = 12;
        } else {
          value = 6;
        }
      }
      return value;
    },
    onResizeComponent() {
      var container = this.$refs.myCardContainer;
      var width = 0;
      var height = 0;
      if (container !== undefined && container !== null) {
        width = container.clientWidth;
        height = container.clientHeight;
      }
      if (
        this.myContainerWidth !== width &&
        width !== undefined &&
        width !== 0
      ) {
        //Write to store?
        this.myContainerWidth = width - 50;
        this.myContainerHeight = height;
      }
    }
  }
};
</script>
