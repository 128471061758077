import Vue from "vue";
import Vuetify from "vuetify";

// Helpers
import colors from "vuetify/es5/util/colors";

export const PRIMARY = "primary";
export const SECONDARY = "secondary";
export const MENU_ITEM_COLOR = "menuItemColor";
export const SIDE_BAR_COLOR = "sideBarColor";
export const MENU_COLOR_HIGHLIGHT = "menuColorHighlight";
export const MENU_COLOR_HIGHLIGHT_BACKGROUND = "menuColorHighlightBackground";
export const MENU_ITEM_COLOR_RIGHT = "menuItemColorRightRight";
export const MENU_ITEM_COLOR_HIGHLIGHT_RIGHT = "menuColorHighlightRight";
export const BOTTOM_BAR_ITEM = "bottomBarItem";
export const BOTTOM_BAR_ITEM_HIGHLIGHT = "BottomBarItemHighlight";
export const LINE = "line";
export const TEXT = "primaryText";
export const GRAY = "gray";
export const ACCENT = "accent";
export const LIGHT1 = "light1";
export const MEDIUM = "medium";
export const MEDIUM_LIGHT = "mediumLight";
export const SUCCESS = "success";
export const ERROR = "error";
export const FAFA = "fafa";
export const BACKGROUND = "background";
export const ROW_BACKGROUND = "rowBackground";
export const ROW_HIGHLIGHTED = "rowHighlighted";
export const NORMAL = "normal";
export const CHART_GOOD = "chartGood";
export const CHART_BAD = "chartBad";
export const ICON_COLOR = "iconColor";
export const ICON_COLOR_LIGHT = "iconColorLight";
export const ICON_COLOR_HIGHLIGHT = "iconHighlight";

export const myCurrentTheme = "default";

export const getTheme = function (theme) {
  var myTheme = myCurrentTheme;
  if (theme !== undefined && theme !== null) {
    myTheme = theme;
  } //blueLiteDarkBlue
  return getThemeColor[myTheme];
};

export const getCurrentTheme = (isDark) => {
  return getTheme().themes[isDark ? "dark" : "light"];
};

export const getThemeColors = function (name) {
  return getThemeColor[name];
};

export const getThemeColor = {
  redWhiteHard: {
    themes: {
      light: {
        primary: "#a83838",
        secondary: "#4AC65C",
        sideBarColor: "#ffffff",
        menuItemColor: "#7c7c7c",
        menuColorHighlight: "#a83838",
        fontAccent: "#a83838",
        fontNormal: "#7c7c7c",
        fontSoft: "#E4E9EA",
        accent: "#a83838",
        rowHighlighted: "#e8e8e8",
        rowBackground: "#FFFFFF",
        iconColorLight: "#a83838",
        iconColor: "#7c7c7c",
        iconHighlight: "#a83838",
      },
    },
  },
  orangeWhiteHard: {
    themes: {
      light: {
        primary: "#BE850B",
        secondary: "#4AC65C",
        sideBarColor: "#ffffff",
        menuItemColor: "#7c7c7c",
        menuColorHighlight: "#a83838",
        fontAccent: "#a83838",
        fontNormal: "#7c7c7c",
        fontSoft: "#E4E9EA",
        accent: "#a83838",
        rowHighlighted: "#e8e8e8",
        rowBackground: "#FFFFFF",
        iconColorLight: "#a83838",
        iconColor: "#7c7c7c",
        iconHighlight: "#a83838",
      },
    },
  },
  blueWhiteHard: {
    themes: {
      light: {
        primary: "#8dc1fc", //primary
        secondary: "#4AC65C",
        sideBarColor: "#ffffff",
        menuItemColor: "#7c7c7c",
        menuColorHighlight: "#8dc1fc",
        fontAccent: "#8dc1fc",
        fontNormal: "#7c7c7c",
        fontSoft: "#E4E9EA",
        accent: "#a83838",
        rowHighlighted: "#e8e8e8",
        rowBackground: "#FFFFFF",
        iconColorLight: "#a83838",
        iconColor: "#7c7c7c",
        iconHighlight: "#8dc1fc",
      },
    },
  },
  pinkWhiteHard: {
    themes: {
      light: {
        primary: "#FBA0E7",
        secondary: "#4AC65C",
        sideBarColor: "#ffffff",
        menuItemColor: "#7c7c7c",
        menuColorHighlight: "#FBA0E7",
        fontAccent: "#FBA0E7",
        fontNormal: "#7c7c7c",
        fontSoft: "#E4E9EA",
        accent: "#a83838",
        rowHighlighted: "#e8e8e8",
        rowBackground: "#FFFFFF",
        iconColorLight: "#FBA0E7",
        iconColor: "#7c7c7c",
        iconHighlight: "#FBA0E7",
        configColor: "E1E1E1",
      },
    },
  },
  orangeWhiteSoft: {
    themes: {
      light: {
        primary: "#BE850B",
        secondary: "#4AC65C",
        sideBarColor: "#ffffff",
        menuItemColor: "#a83838",
        menuColorHighlight: "#BE850B",
        fontAccent: "#BE850B",
        fontNormal: "#E4E9EA",
        fontSoft: "#E9E9E9",
        accent: "#BE850B",
        rowHighlighted: "#e8e8e8",
        rowBackground: "#FFFFFF",
        iconColorLight: "#a83838",
        iconColor: "#a83838",
        iconHighlight: "#BE850B",
        configColor: "E1E1E1",
      },
    },
  },
  orangeLite: {
    themes: {
      light: {
        primary: "#BE850B",
        secondary: "#8dc1fc",
        sideBarColor: "#f9f9f9",
        sideBarColorRight: "#f9F9F9",
        tileBackgroundColor: "#ffffff",
        topBarBackgroundColor: "#ffffff",
        bottomBarBackgroundColor: "#ffffff",
        menuItemColor: "#7c7c7c",
        menuColorHighlight: "#BE850B",
        fontAccent: "#a83838",
        fontNormal: "#000000",
        fontSoft: "#E4E9EA",
        accent: "#BE850B",
        rowHighlighted: "#e8e8e8",
        rowBackground: "#FFFFFF",
        tileBackgroundColor: "#FFFFFF", //added
        iconColorLight: "#a83838",
        iconColor: "#000000",
        iconHighlight: "#BE850B",
        configColor: "E1E1E1",
      },
    },
  },
  orangeLiteDark: {
    themes: {
      light: {
        primary: "#BE850B",
        secondary: "#8dc1fc",
        sideBarColor: "#1D1D1D",
        sideBarColorRight: "#ffffff",
        tileBackgroundColor: "#F9F9F9",
        menuItemColor: "#ffffff",
        menuColorHighlight: "#BE850B",
        menuColorHighlightBackground: "#BE850B33",
        fontAccent: "#a83838",
        fontNormal: "#000000",
        fontSoft: "#E4E9EA",
        accent: "#BE850B",
        rowHighlighted: "#e8e8e8",
        rowBackground: "#FFFFFF",
        iconColorLight: "#E4E9EA",
        iconColor: "#000000",
        iconHighlight: "#BE850B",
        chartGood: "#BE850B",
        chartGood1: "#AcAcAc",
        ChartColorNeutral: "#D0D0D0",
        chartBad: "#AB0A3D",
        chartBad1: "#AB0A3D",
        configColor: "E1E1E1",
      },
    },
  },
  engieTheme: {
    themes: {
      light: {
        primary: "#0EAAFE",
        secondary: "#8dc1fc",
        sideBarColor: "#0EAAFE",
        sideBarColorRight: "#ffffff",
        tileBackgroundColor: "#F9F9F9",
        topBarBackgroundColor: "#FFFFFF",
        bottomBarBackgroundColor: "#F9F9F9",
        menuItemColor: "#AEDEFF",
        menuColorHighlight: "#ffffff",
        menuColorHighlightBackground: "#ffffff33",
        menuItemColorRight: "#7c7c7c",
        menuColorHighlightRight: "#FE5722",
        fontAccent: "#FE5722",
        fontNormal: "#000000",
        fontSoft: "#E4E9EA",
        accent: "#FE5722",
        rowHighlighted: "#e8e8e8",
        rowBackground: "#FFFFFF",
        iconColorLight: "#E4E9EA",
        iconColor: "#000000",
        iconHighlight: "#FE5722",
        chartGood: "#BFCE00",
        chartGood1: "#0FAAFF",
        ChartColorNeutral: "#055EAA",
        chartBad: "#E62D87",
        chartBad1: "#E1011B",
        configColor: "E1E1E1",
      },
    },
  },
  blueLiteDarkBlue1: {
    themes: {
      light: {
        primary: "#699DEE",
        secondary: "#8dc1fc",
        sideBarColor: "#233862",
        sideBarColorRight: "#ffffff",
        tileBackgroundColor: "#f9fafc",
        topBarBackgroundColor: "#ffffff",
        bottomBarBackgroundColor: "#fafafa",
        menuItemColor: "#ffffff",
        menuColorHighlight: "#699DEE",
        menuColorHighlightBackground: "#314c7e",
        menuItemColorRight: "#7c7c7c",
        menuColorHighlightRight: "#699DEE",
        fontAccent: "#699DEE",
        fontNormal: "#000000",
        fontSoft: "#E4E9EA",
        accent: "#699DEE",
        rowHighlighted: "#e8e8e8",
        rowBackground: "#FFFFFF",
        iconColorLight: "#E4E9EA",
        iconColor: "#000000",
        iconHighlight: "#699DEE",
        chartGood: "#699DEE",
        chartGood1: "#233862",
        ChartColorNeutral: "#D0D0D0",
        chartBad: "#ED7D2B",
        chartBad1: "#ED7D2B",
        configColor: "E1E1E1",
      },
    },
  },
  default: {
    dark: false,
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primaryText: "#000000",
        primary: "#3661ED",
        secondary: "#F3B860",
        tertiary: "#ED7162",
        quaternary: "#69CDC3",
        quinary: "#AE82FC",
        line: "#EDEBF1",
        card: "#FFFFFF",
        sideBarColor: "#eaf1fb",
        sideBarColorRight: "#ffffff",
        tileBackgroundColor: "#f8f9fb",
        topBarBackgroundColor: "#ffffff",
        bottomBarBackgroundColor: "#fafafa",
        gray: "#717B87",
        menuItemColor: "#7c7c7c",
        menuColorHighlight: "#3661ED",
        menuColorHighlightBackground: "#d3e3fd",
        menuItemColorRight: "#7c7c7c",
        menuColorHighlightRight: "#3661ED",
        fontAccent: "#3661ED",
        fontNormal: "#000000",
        fontSoft: "#E4E9EA",
        accent: "#3661ED",
        rowHighlighted: "#e8e8e8",
        rowBackground: "#FFFFFF",
        iconButton: "#F7F9FB",
        iconColorLight: "#E4E9EA",
        iconColor: "#000000",
        iconHighlight: "#3661ED",
        chartGood: "#3661ED",
        chartGood1: "#233862",
        ChartColorNeutral: "#D0D0D0",
        chartBad: "#FFCB78",
        chartBad1: "#ED7D2B",
        configColor: "E1E1E1",
        success: "#4caf50",
        error: "#ff5252",
        water: "#a6cbe3",
        warning: "#fb8c00",
      },
      dark: {
        primaryText: "#ffffff",
        anchor: "#527bff",
        primary: "#3661ED",
        secondary: "#F3B860",
        tertiary: "#ED7162",
        quaternary: "#69CDC3",
        quinary: "#AE82FC",
        card: "#1d1d22",
        sideBarColor: "#121212",
        sideBarColorRight: "#1B1B1C",
        tileBackgroundColor: "#18181b",
        topBarBackgroundColor: "#ffffff",
        bottomBarBackgroundColor: "#fafafa",
        gray: "#717B87",
        line: "#383838",
        menuItemColor: "#7c7c7c",
        menuColorHighlight: "#3661ED",
        menuColorHighlightBackground: "#242429",
        menuItemColorRight: "#7c7c7c",
        menuColorHighlightRight: "#3661ED",
        fontAccent: "#3661ED",
        fontNormal: "#000000",
        fontSoft: "#E4E9EA",
        accent: "#3661ED",
        rowHighlighted: "#e8e8e8",
        rowBackground: "#FFFFFF",
        iconButton: "#18181b",
        iconColorLight: "#E4E9EA",
        iconColor: "#000000",
        iconHighlight: "#3661ED",
        chartGood: "#3661ED",
        chartGood1: "#233862",
        ChartColorNeutral: "#D0D0D0",
        chartBad: "#FFCB78",
        chartBad1: "#ED7D2B",
        configColor: "E1E1E1",
        success: "#4caf50",
        error: "#ff5252",
        water: "#000000",
        warning: "#fb8c00",
      },
    },
  },
  belsol: {
    themes: {
      light: {
        primary: "#FFD745",
        secondary: "#C6C6C6",
        sideBarColor: "#000000",
        sideBarColorRight: "#ffffff",
        tileBackgroundColor: "#fafafa",
        topBarBackgroundColor: "#ffffff",
        bottomBarBackgroundColor: "#fafafa",
        menuItemColor: "#ffffff",
        menuColorHighlight: "#FDF000",
        menuColorHighlightBackground: "#FDF00033",
        menuItemColorRight: "#7c7c7c",
        menuColorHighlightRight: "#FDF000",
        fontAccent: "#FFD745",
        fontNormal: "#000000",
        fontSoft: "#E4E9EA",
        accent: "#FFD745",
        rowHighlighted: "#e8e8e8",
        rowBackground: "#FFFFFF",
        iconColorLight: "#E4E9EA",
        iconColor: "#000000",
        iconHighlight: "#FDF000",
        chartGood: "#FDF000",
        chartGood1: "#C6C6C6",
        ChartColorNeutral: "#000000",
        chartBad: "#FFC272",
        chartBad1: "#FFB799",
        configColor: "E1E1E1",
      },
    },
  },
  blueLiteDarkBlue: {
    themes: {
      light: {
        primary: "#61D8FF",
        secondary: "#8dc1fc",
        sideBarColor: "#000620",
        sideBarColorRight: "#ffffff",
        tileBackgroundColor: "#F9F9F9",
        topBarBackgroundColor: "#F9F9F9",
        bottomBarBackgroundColor: "#F9F9F9",
        menuItemColor: "#ffffff",
        menuColorHighlight: "#61D8FF",
        menuColorHighlightBackground: "#61D8FF33",
        menuItemColorRight: "#7c7c7c",
        menuColorHighlightRight: "#61D8FF",
        fontAccent: "#61D8FF",
        fontNormal: "#000000",
        fontSoft: "#E4E9EA",
        accent: "#61D8FF",
        rowHighlighted: "#e8e8e8",
        rowBackground: "#FFFFFF",
        iconColorLight: "#E4E9EA",
        iconColor: "#000000",
        iconHighlight: "#61D8FF",
        chartGood: "#61D8FF",
        chartGood1: "#000620",
        ChartColorNeutral: "#D0D0D0",
        chartBad: "#ED7D2B",
        chartBad1: "#ED7D2B",
        configColor: "E1E1E1",
      },
    },
  },
  pinkLiteDark: {
    themes: {
      light: {
        primary: "#FBA0E7",
        secondary: "#8dc1fc",
        sideBarColor: "#891659",
        sideBarColorRight: "#ffffff",
        tileBackgroundColor: "#F9F9F9",
        topBarBackgroundColor: "#F9F9F9",
        bottomBarBackgroundColor: "#F9F9F9",
        menuItemColor: "#ffffff",
        menuColorHighlight: "#61D8FF",
        menuItemColorRight: "#7c7c7c",
        menuColorHighlightRight: "#FFFFFF",
        fontAccent: "#FBA0E7",
        fontNormal: "#000000",
        fontSoft: "#E4E9EA",
        accent: "#FBA0E7",
        rowHighlighted: "#e8e8e8",
        rowBackground: "#FFFFFF",
        iconColorLight: "#FBA0E7",
        iconColor: "#000000",
        iconHighlight: "#FBA0E7",
        chartGood: "#FBA0E7",
        chartGood1: "#891659",
        ChartColorNeutral: "#D0D0D0",
        chartBad: "#8A4616",
        chartBad1: "#8A4616",
        configColor: "E1E1E1",
      },
    },
  },
  blueLiteDark: {
    themes: {
      light: {
        primary: "#61D8FF",
        secondary: "#8dc1fc",
        sideBarColor: "#1D1D1D",
        sideBarColorRight: "#f9F9F9",
        menuItemColor: "#7c7c7c",
        menuColorHighlight: "#61D8FF",
        menuItemColorRight: "#7c7c7c",
        menuColorHighlightRight: "#61D8FF",
        fontAccent: "#61D8FF",
        fontNormal: "#000000",
        fontSoft: "#E4E9EA",
        accent: "#61D8FF",
        rowHighlighted: "#e8e8e8",
        rowBackground: "#FFFFFF",
        iconColorLight: "#E4E9EA",
        iconColor: "#000000",
        iconHighlight: "#61D8FF",
        configColor: "E1E1E1",
      },
    },
  },
};

export const getThemeBlackRed = function (name) {
  var theme = {};
  theme[PRIMARY] = "#a83838";
  theme[SECONDARY] = "#4AC65C";
  theme[MENU_ITEM_COLOR] = "#000000";
  theme[SIDE_BAR_COLOR] = "#fafafa";
  theme[MENU_COLOR_HIGHLIGHT] = "#a83838";
  theme[BOTTOM_BAR_ITEM] = "#000000";
  theme[BOTTOM_BAR_ITEM_HIGHLIGHT] = "#a83838";
  theme[ICON_COLOR_LIGHT] = "#d8d6d6";
  theme[ICON_COLOR] = "#000000";
  theme[ICON_COLOR_HIGHLIGHT] = "#a83838";
  theme[ACCENT] = "#a83838";
  theme[LIGHT1] = "#FBFCFF";
  theme[MEDIUM] = "#8B8B9C";
  theme[MEDIUM_LIGHT] = "#E4E9EA";
  theme[SUCCESS] = "#4AC65C";
  theme[ERROR] = "#FF5252";
  theme[FAFA] = "#fafafa";
  theme[BACKGROUND] = "#F9FAFD";
  theme[NORMAL] = "#3a3a3a";
  theme[CHART_GOOD] = "#a83838";
  theme[CHART_BAD] = "#a83838";
  return theme;
};

//Blauw primary en zachte kleuren
export const getThemeSoftBlue = function () {
  var theme = {};
  theme[PRIMARY] = "#8dc1fc";
  theme[SECONDARY] = "#8dc1fc";
  theme[MENU_ITEM_COLOR] = "#9f9f9f";
  theme[MENU_ITEM_COLOR_RIGHT] = "#8B8B9C";
  theme[MENU_ITEM_COLOR_HIGHLIGHT_RIGHT] = "#8B8B9C";
  theme[SIDE_BAR_COLOR] = "#fafafa";
  theme[MENU_COLOR_HIGHLIGHT] = "#8dc1fc";
  theme[BOTTOM_BAR_ITEM] = "#7c7c7c";
  theme[BOTTOM_BAR_ITEM_HIGHLIGHT] = "#1976d2";
  theme[ICON_COLOR_LIGHT] = "#d8d6d6";
  theme[ICON_COLOR] = "#7c7c7c";
  theme[ICON_COLOR_HIGHLIGHT] = "#8dc1fc";
  theme[ACCENT] = "#8dc1fc"; //"#4696c4";
  theme[LIGHT1] = colors.blue.lighten4;
  theme[MEDIUM] = "#8B8B9C";
  theme[MEDIUM_LIGHT] = "#E4E9EA";
  theme[SUCCESS] = "#4AC65C";
  theme[ERROR] = "#FF5252";
  theme[FAFA] = "#fafafa";
  theme[BACKGROUND] = "#F9FAFD";
  theme[ROW_HIGHLIGHTED] = "#e8e8e8"; //"#bbdefb";
  theme[ROW_BACKGROUND] = "#FFFFFF";
  theme[NORMAL] = "#3a3a3a";
  theme[CHART_GOOD] = "#c6e1ff";
  theme[CHART_BAD] = "#f7b9b7";
  configColor: "E1E1E1";
  return theme;
};

// Oranje en zachte kleuren.
export const getThemeOrange = function () {
  var theme = {};
  theme[PRIMARY] = "#BE850B";
  theme[SECONDARY] = "#8dc1fc";
  theme[MENU_ITEM_COLOR] = "#9f9f9f";
  theme[SIDE_BAR_COLOR] = "#fafafa";
  //theme[MENU_COLOR_HIGHLIGHT] = "#8dc1fc";
  theme[BOTTOM_BAR_ITEM] = "#7c7c7c";
  // theme[BOTTOM_BAR_ITEM_HIGHLIGHT] = "#1976d2";
  theme[ICON_COLOR_LIGHT] = "#d8d6d6";
  theme[ICON_COLOR] = "#7c7c7c";
  theme[ICON_COLOR_HIGHLIGHT] = "#BE850B";
  theme[ACCENT] = "#BE850B"; //"#4696c4";
  theme[LIGHT1] = colors.blue.lighten4;
  theme[MEDIUM] = "#8B8B9C";
  theme[MEDIUM_LIGHT] = "#E4E9EA";
  theme[SUCCESS] = "#4AC65C";
  theme[ERROR] = "#FF5252";
  theme[FAFA] = "#fafafa";
  theme[BACKGROUND] = "#F9FAFD";
  theme[ROW_HIGHLIGHTED] = "#e8e8e8"; //"#bbdefb";
  theme[ROW_BACKGROUND] = "#FFFFFF";
  theme[NORMAL] = "#3a3a3a";
  theme[CHART_GOOD] = "#c6e1ff";
  theme[CHART_BAD] = "#f7b9b7";
  return theme;
};

export const getThemeBlue = function () {
  var theme = {};
  theme[PRIMARY] = "#3FA7C2";
  theme[SECONDARY] = "#8dc1fc";
  theme[MENU_ITEM_COLOR] = "#9f9f9f";
  theme[SIDE_BAR_COLOR] = "#fafafa";
  //theme[MENU_COLOR_HIGHLIGHT] = "#8dc1fc";
  theme[BOTTOM_BAR_ITEM] = "#7c7c7c";
  // theme[BOTTOM_BAR_ITEM_HIGHLIGHT] = "#1976d2";
  theme[ICON_COLOR_LIGHT] = "#d8d6d6";
  theme[ICON_COLOR] = "#7c7c7c";
  theme[ICON_COLOR_HIGHLIGHT] = "#3FA7C2";
  theme[ACCENT] = "#3FA7C2"; //"#4696c4";
  theme[LIGHT1] = colors.blue.lighten4;
  theme[MEDIUM] = "#8B8B9C";
  theme[MEDIUM_LIGHT] = "#E4E9EA";
  theme[SUCCESS] = "#4AC65C";
  theme[ERROR] = "#FF5252";
  theme[FAFA] = "#fafafa";
  theme[BACKGROUND] = "#F9FAFD";
  theme[ROW_HIGHLIGHTED] = "#e8e8e8"; //"#bbdefb";
  theme[ROW_BACKGROUND] = "#FFFFFF";
  theme[NORMAL] = "#3a3a3a";
  theme[CHART_GOOD] = "#c6e1ff";
  theme[CHART_BAD] = "#f7b9b7";
  configColor: "E1E1E1";
  return theme;
};

//ToDetermine
export const getTheme2 = function () {
  var theme = {};
  theme[PRIMARY] = "#1976d2";
  theme[SECONDARY] = "#1976d2";
  theme[MENU_ITEM_COLOR] = "#000000";
  theme[SIDE_BAR_COLOR] = "#fafafa";
  theme[MENU_COLOR_HIGHLIGHT] = "#1976d2";
  theme[ICON_COLOR_LIGHT] = "#d8d6d6";
  theme[ICON_COLOR] = "#000000";
  theme[ICON_COLOR_HIGHLIGHT] = "#1976d2";
  theme[BOTTOM_BAR_ITEM] = "#000000";
  theme[BOTTOM_BAR_ITEM_HIGHLIGHT] = "#1976d2";
  theme[ACCENT] = colors.blue.lighten2;
  theme[LIGHT1] = colors.blue.lighten4;
  theme[MEDIUM] = "#8B8B9C";
  theme[MEDIUM_LIGHT] = "#E4E9EA";
  theme[SUCCESS] = "#4AC65C";
  theme[ERROR] = "#FF5252";
  theme[FAFA] = "#fafafa";
  theme[BACKGROUND] = "#F9FAFD";
  theme[ROW_HIGHLIGHTED] = "#e8e8e8"; //"#bbdefb";
  theme[ROW_BACKGROUND] = "#FFFFFF";
  theme[NORMAL] = "#3a3a3a";
  theme[CHART_GOOD] = "#1976d2";
  theme[CHART_BAD] = "#FF5252";
  configColor: "E1E1E1";
  return theme;
};
