/**
 * App State
 */

import { isEmpty, isTrue } from "../api/utilities";
import {
  AUTHORISATION,
  SYNC_GROUP_CRM,
  SYNC_GROUP_SALES,
  USER_ROLE,
} from "./constants";
import Vue from "vue";

export const ALL_ACTION_FILTER = "AllActionFilter";
export const ACTION_FILTER = "Filter";
export const ACTION_EDIT = "Edit";
export const ACTION_READ = "Read";
export const EDIT_FILTER = "EditFilter";

export const CAN_READ = "canRead";
export const CAN_CREATE = "canCreate";
export const CAN_EDIT = "canEdit";

export default {
  state: {},
  mutations: {},
  getters: {
    getAuthorisations(state, getters) {
      return getters.getDataObjectsForObjectType(AUTHORISATION);
    },

    userHasAuthorisation: (state, getters) => (payload) => {
      var objectConfig = getters.getObjectConfigForType(payload.objectType);
      if (isEmpty(objectConfig)) {
        return false;
      }
      var hasAuthorisation = false;
      var syncGroup = objectConfig.objectConfig.syncGroup;
      if (syncGroup === SYNC_GROUP_CRM || syncGroup === SYNC_GROUP_SALES) {
        var authorisations = getters.getAuthorisations;
        var authorisationsForObject = authorisations.filter(function (o) {
          return o.objectType === payload.objectType;
        });

        for (var i = 0; i < authorisationsForObject.length; i++) {
          var authorisation = authorisationsForObject[i];
          if (isTrue(authorisation[payload.action])) {
            hasAuthorisation = true;
            break;
          }
        }
      } else {
        return true;
        //Check of we configmanipulation hebben.
      }
      var options = {
        hasAuthorisation,
        authorisations: authorisationsForObject,
        action: payload.action,
      };
      hasAuthorisation = objectConfig.hasCustomAuthorisation(
        payload.object,
        payload.viewConfig,
        null,
        options,
      );

      return hasAuthorisation;
    },
    userHasAuthorisationForFilter: (state, getters) => (payload) => {
      if (getters.isConfigAdmin) {
        return true;
      }
      if (getters.isClientAdmin) {
        var listConfig = payload.objectType + "List";
        if (payload.viewConfig === listConfig) {
          return true;
        }
      }
      return false;
    },
    isConfigAdmin(state, getters) {
      var authorisations = getters.getAuthorisations;
      var authorisationsForObject = authorisations.find(function (o) {
        return (
          o.object === "ConfigManipulation" && o.auth === "ConfigManipulation"
        );
      });
      return !isEmpty(authorisationsForObject);
    },
    isClientAdmin(state, getters) {
      var authorisations = getters.getAuthorisations;
      var authorisationsForObject = authorisations.find(function (o) {
        return o.object === "ClientAdmin" && o.auth === "ClientAdmin";
      });
      return !isEmpty(authorisationsForObject);
    },
    isCRMAdmin(state, getters) {
      var authorisations = getters.getAuthorisations;
      var authorisationsForObject = authorisations.find(function (o) {
        return o.object === "CRMAdmin" && o.auth === "CRMAdmin";
      });
      return !isEmpty(authorisationsForObject);
    },
  },
  actions: {
    //Momenteel niet gebruikt
    async updateAuthorisations({ dispatch, commit, getters }, payload) {
      var newAuthorisations = [];
      var currentAuthorisations = getters.getAuthorisations;

      //Delete all the ones which are not active anymore
      for (var i = 0; i < currentAuthorisations.lenght; i++) {
        var currentAuth = currentAuthorisations[i];
        var newAuth = newAuthorisations.find(function (o) {
          return o.authorisation === currentAuth.authorisation;
        });
        if (isEmpty(newAuth)) {
          //We have to delete the current
          currentAuth.isDeleted = 1;
          //Here we have to save
        } else {
          //We found a match
          //We can delete from the new Auth
          newAuthorisations.remove(newAuth);
        }
      }
      //Now we have to add the remainng
    },
  },
};
