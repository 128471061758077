<template>
  <div class=" card progress-circular-wrap">
    <v-card
      id="loadingview"
      class="text-xs-center transparent "
      flat
    >
    
      <img
          :src="getTenantIcon"
          crossorigin="anonymous"
          height="240px"
          width="240px"
          cover
          class="rounded-lg"
        />
        <v-progress-linear
        height=10
        rounded
        style="margin-top: 10px; margin-bottom: 10px"
        v-model="getProgress"
        color="grey lighten-2"
      >

       
      </v-progress-linear>
      <!-- <div class="text-center text-body-2 gray--text opaque-5 py-2 font-weight-medium"> {{ getProgress }} %</div> -->
    </v-card>
    <v-container
      class="bottomInfo "
      v-if="isResetButton"
    >
      <v-row
        class="flex-column"
        align="center"
        justify="centers"
      >
        <p>
          Problems with loading?
        </p>
        <v-row
          class="flex-column"
          align="center"
          justify="centers"
        >
          <v-btn
            class="my-1 mb-2"
            @click="reload"
          >
            Retry
          </v-btn>
          <v-btn
            color="error"
            @click="clearDataPopup"
          >
            Reset
          </v-btn>
        </v-row>
      </v-row>
    </v-container>
    <v-dialog
      v-model="clearDialog"
      max-width="400"
    >
      <v-card>
        <v-card-title class="text-h5">
          Are you sure you want to reset?
        </v-card-title>
        <v-card-text>
          All your local saved changes will be lost
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            text
            @click="clearDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="error"
            @click="clearData"
          >
            Reset
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      isResetButton: false,
      clearDialog: false
    };
  },
  computed: {
    ...mapGetters(['getTenantIcon']),
    getTotalSteps() {
      return 300;
    },
    getProgress() {
      return Math.trunc(this.$store.getters.getAppLoginStatus);
    }
  },
  mounted() {
    if (this.loading === true) {
      setTimeout(this.showResetButton, 90000)
    }
  },
  watch: {
    loading(value) {
      if (this.loading === true) {
        setTimeout(this.showResetButton, 90000)
      }
    }
  },
  methods: {
    ...mapActions(["logout"]),
    showResetButton() {
      this.isResetButton = true
    },
    clearDataPopup() {
      this.clearDialog = true
    },
    async clearData() {
      this.clearDialog = false
      await this.$store.dispatch("deleteObjectContext");
      this.logoutApp()
    },
    async logoutApp() {
      setTimeout(this.reload, 1000)
      this.logout()
    },
    reload() {
      location.reload()
    },
  }
};
</script>
<style scoped>
.progress-circular-wrap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bottomInfo {
  position: absolute;
  bottom: 60px;
}
</style>
