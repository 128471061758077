<template>
  <v-menu
    :rounded="'lg'"
    v-model="showMenu"
    :close-on-content-click="false"
    bottom
    right
    offset-y
  >
    <template v-slot:activator="{ on }">
      <v-btn
        slot="activator"
        :class="showMenu ? 'primary--text' : ''"
        large
        icon
        v-on="on"
        @click="openMenu"
      >
        <v-badge
          :content="getMyUnreadNotifications.length"
          :value="getMyUnreadNotifications.length"
          color="error"
          class="notificationBadge"
          bordered
          overlap
        >
          <v-icon class="configLight">{{ showMenu ? 'hi-bell' : 'hi-bell-outline' }}</v-icon>
        </v-badge>
        <!-- <v-avatar
            
            class="rounded-xl"
            size="32px"
          >
            <img
              :src="getAvatar"
              alt="avatar"
            >
          </v-avatar> -->
      </v-btn>
    </template>
    <v-container
      :style="`width: ${MENU_NOTIFICATION_SIZE}px`"
      class="pa-0 ma-0"
    >
      <v-tabs v-model="tab">
        <v-tabs-slider color="primary" />

        <v-tab
          v-for="tabItem in tabItems"
          :key="tabItem"
          class="text-body-2"
        >
          {{ tabItem }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="tabItem in tabItems"
          :key="tabItem"
        >
          <v-list
            color="card"
            class="notificationContainer"
          >
            <div
              v-for="(group, index) in getTabNotifications(tabItem)"
              :key="index"
            >
              <v-list-group
                v-if="group.notifications.length > 1"
                no-action
                sub-group
              >
                <template v-slot:activator>

                  <v-list-item-avatar
                    color="tileBackgroundColor"
                    style="overflow: visible;"
                  >
                    <v-badge
                      :content="group.notifications.length"
                      :value="group.notifications.length"
                      :color="group.IsRead ? 'gray' : 'primaryText'"
                      class="notificationBadge"
                      bordered
                      overlap
                    >
                      <v-icon>{{ getIcon('NotificationType', group.NotificationType) }}</v-icon>
                    </v-badge>
                  </v-list-item-avatar>



                  <v-list-item-content>
                    <!-- <v-list-item-subtitle
                      class="text-body-2"
                      v-html="`${group.notifications.length} notifications`"
                    /> -->
                    <v-list-item-title
                      :style="isTrue(group.IsRead) && 'opacity: .6;'"
                      class="font-weight-mediumBold"
                      v-html="`${formatTitle(group, 'NotificationType', group.NotificationType)}`"
                    />

                    <v-list-item-subtitle
                      class="text-caption opaque-6"
                      v-html="getRelativeDate(group)"
                    />
                  </v-list-item-content>
                  <v-list-item-action v-if="!isTrue(group.IsRead)">
                    <v-tooltip
                      content-class="rounded-lg"
                      color="white"
                      bottom
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-hover v-slot="{ hover }">
                          <v-btn
                            v-bind="attrs"
                            icon
                            v-on="on"
                            @click="markAsReadGroup(group)"
                          >

                            <v-icon
                              v-if="hover"
                              color="primary"
                            >hi-check-circle</v-icon>
                            <div
                              v-else
                              class="primary dot"
                            />

                          </v-btn>
                        </v-hover>
                      </template>
                      <span class="text-caption primary--text font-weight-mediumBold">Mark {{ group.notifications.length
                        }} as read</span>
                    </v-tooltip>
                    <!-- <v-icon
                  v-else
                  color="fontSoft"
                >hi-check-circle-outline</v-icon> -->

                  </v-list-item-action>
                </template>

                <v-list-item
                  v-for="(item, index) in group.notifications"
                  :key="index"
                  @click="() => { }"
                >

                  <v-list-item-avatar
                    color="tileBackgroundColor"
                    @click="clickNotification(item)"
                  >
                    <v-icon>{{ getIcon('NotificationType', item.NotificationType) }}</v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content @click="clickNotification(item)">
                    <v-list-item-title
                      :style="isTrue(item.IsRead) && 'opacity: .6;'"
                      class="font-weight-mediumBold"
                      v-html="formatTitle(item, 'NotificationType', item.NotificationType)"
                    />
                    <v-list-item-subtitle
                      class="text-body-2"
                      v-html="item.Subject"
                    />
                    <v-list-item-subtitle
                      class="text-caption opaque-6"
                      v-html="getRelativeDate(item)"
                    />
                  </v-list-item-content>
                  <v-list-item-action v-if="!isTrue(item.IsRead)">
                    <v-tooltip
                      content-class="rounded-lg"
                      color="white"
                      bottom
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-hover v-slot="{ hover }">
                          <v-btn
                            v-bind="attrs"
                            icon
                            v-on="on"
                            @click="markAsRead(item)"
                          >

                            <v-icon
                              v-if="hover"
                              color="primary"
                            >hi-check-circle</v-icon>
                            <div
                              v-else
                              class="primary dot"
                            />

                          </v-btn>
                        </v-hover>
                      </template>
                      <span class="text-caption primary--text font-weight-mediumBold">Mark as read</span>
                    </v-tooltip>
                    <!-- <v-icon
                  v-else
                  color="fontSoft"
                >hi-check-circle-outline</v-icon> -->

                  </v-list-item-action>
                </v-list-item>
                <v-list-item
                  v-if="getTabNotifications(tabItem).length === 0"
                  class="card"
                >
                  <v-card
                    class="d-flex align-center justify-center flex-column px-8 py-3 pb-8 kingSize card"
                    flat
                    tile
                  >
                    <v-icon
                      class="ma-2 "
                      color="fontSoft"
                      large
                    >
                      hi-bell
                    </v-icon>

                    <div
                      class="text-subtitle-2 py-1"
                      v-text="`No ${tabItem} Notifications`"
                    />

                    <div
                      class="text-caption opaque-6"
                      v-text="`You currently have no ${tabItem.toLowerCase()} notifications`"
                    />
                  </v-card>

                </v-list-item>
              </v-list-group>
              <v-list-item
                v-else
                @click="() => { }"
              >

                <v-list-item-avatar
                  color="tileBackgroundColor"
                  @click="clickNotification(group.notifications[0])"
                >
                  <v-icon>{{ getIcon('NotificationType', group.notifications[0].NotificationType) }}</v-icon>
                </v-list-item-avatar>

                <v-list-item-content @click="clickNotification(group.notifications[0])">
                  <v-list-item-title
                    :style="isTrue(group.notifications[0].IsRead) && 'opacity: .6;'"
                    class="font-weight-mediumBold"
                    v-html="formatTitle(group.notifications[0], 'NotificationType', group.notifications[0].NotificationType)"
                  />
                  <v-list-item-subtitle
                    class="text-body-2"
                    v-html="group.notifications[0].Subject"
                  />
                  <v-list-item-subtitle
                    class="text-caption opaque-6"
                    v-html="getRelativeDate(group.notifications[0])"
                  />
                </v-list-item-content>
                <v-list-item-action v-if="!isTrue(group.notifications[0].IsRead)">
                  <v-tooltip
                    content-class="rounded-lg"
                    color="white"
                    bottom
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-hover v-slot="{ hover }">
                        <v-btn
                          v-bind="attrs"
                          icon
                          v-on="on"
                          @click="markAsRead(group.notifications[0])"
                        >

                          <v-icon
                            v-if="hover"
                            color="primary"
                          >hi-check-circle</v-icon>
                          <div
                            v-else
                            class="primary dot"
                          />

                        </v-btn>
                      </v-hover>
                    </template>
                    <span class="text-caption primary--text font-weight-mediumBold">Mark as read</span>
                  </v-tooltip>
                  <!-- <v-icon
                  v-else
                  color="fontSoft"
                >hi-check-circle-outline</v-icon> -->

                </v-list-item-action>
              </v-list-item>

            </div>

          </v-list>
          <v-list-item
            v-if="noItems(getTabNotifications(tabItem))"
            class="card"
          >
            <v-card
              class="d-flex align-center justify-center flex-column px-8 py-3 pb-8 kingSize card"
              flat
              tile
            >
              <v-icon
                class="ma-2 "
                color="fontSoft"
                large
              >
                hi-bell
              </v-icon>

              <div
                class="text-subtitle-2 py-1"
                v-text="`No ${tabItem} Notifications`"
              />

              <div
                class="text-caption opaque-6"
                v-text="`You currently have no ${tabItem.toLowerCase()} notifications`"
              />
            </v-card>

          </v-list-item>
        </v-tab-item>
      </v-tabs-items>
    </v-container>


  </v-menu>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import { isEmpty, isTrue } from '../../api/utilities';
import { DATE_FORMAT, GET_VIEW_CONFIG_FOR_ID, MENU_NOTIFICATION_SIZE, SELECT_OBJECT } from '../../store/constants';



export default {
  data() {
    return {
      showMenu: false,
      tab: 0,
      tabItems: [
        'Unread',
        'Read'
      ],
      MENU_NOTIFICATION_SIZE,
    }
  },
  computed: {
    ...mapGetters(['getCurrentEmployeeNotifications']),
    getFields() {
      var fields = [];
      if (this.getSubviewConfig != undefined) {
        var payload = {
          fieldList: this.getSubviewConfig,
          size: "1",
          list: 'display'
        };
        fields = this.$store.getters.getFieldsForViewFieldListAndSize(payload);
      }
      fields.sort(function (a, b) {
        a.position > b.position;
      });
      return fields;
    },
    getMyUnreadNotifications() {
      return this.getCurrentEmployeeNotifications.filter(o => !isTrue(o.IsRead))
    },
    getMyUnreadGroupNotifications() {
      return this.groupNotifications(this.getMyUnreadNotifications)
    },
    getMyReadNotifications() {
      return this.getCurrentEmployeeNotifications.filter(o => isTrue(o.IsRead))
    },
    getMyReadGroupNotifications() {
      return this.groupNotifications(this.getMyReadNotifications)
    },
    getSubviewConfig() {
      if (this.getViewConfig === undefined) {
        return "";
      }
      return this.getViewConfig.subViewConfig;
    },
    getViewConfig() {
      return this.$store.getters[GET_VIEW_CONFIG_FOR_ID](
        'NotificationMainList'
      );
    },
    getObjectConfig() {
      return this.$store.getters.getObjectConfigForType(
        this.getViewConfig.objectType
      );
    },
  },
  methods: {
    isTrue,
    groupNotifications(notifications) {
      var groups = {}
      for (let index = 0, l = notifications.length; index < l; index++) {
        const notification = notifications[index];
        const notifDate = moment(notification.createdAt).format(DATE_FORMAT)
        const id = `${notifDate}-${notification.NotificationType}`
        if (groups[id]) {
          if (moment(notification.createdAt).isAfter(moment(groups[id].createdAt))) {
            groups[id].createdAt = notification.createdAt
          }
          groups[id].notifications.push(notification)
        } else {

          groups[id] = {
            createdAt: notification.createdAt,
            IsRead: notification.IsRead,
            NotificationType: notification.NotificationType,
            notifications: [
              notification
            ]
          }
        }
      }
      return groups
    },
    getField(fieldName) {
      return this.getFields.find(item => item.value === fieldName)
    },
    getListCode(fieldName) {
      const field = this.getField(fieldName)
      if (field) return field.dropdown
      return field
    },
    getValueFromValueList(fieldName, value) {
      const listCode = this.getListCode(fieldName)
      let list = this.$store.getters.getValueListForId(listCode)
      if (value && Array.isArray(list)) {
        const item = list.find(item => item.value === value)
        return item
      }
      return value;
    },
    getDescr(fieldName, value) {
      const item = this.getValueFromValueList(fieldName, value)
      if (item && item.descr) {
        return item.descr
      }
      return value;
    },
    formatTitle(item, fieldName, value) {
      const title = this.getDescr(fieldName, value)
      if (title) {
        let formatted = title.replace("{Creator}", this.getEmployeeName(item.Creator));
        formatted = formatted.replace("{Employee}", 'you');
        return formatted
      }
      return value;
    },
    getEmployeeName(employeeGuid) {
      var employees = this.$store.getters.getDataObjectsForObjectType("EmployeeMain");
      const employee = employees.find(o => o.Guid === employeeGuid);
      if (employee) {
        return employee.FirstName
      }
      return 'An employee'
    },
    getIcon(fieldName, value) {
      const objectConfig = this.getObjectConfig
      const item = this.getValueFromValueList(fieldName, value)
      if (item) {
        return item.icon
      }

      if (objectConfig) {
        return objectConfig.icon
      }
      return 'hi-bell';
    },
    getTabNotifications(tab) {
      if (tab === 'Unread') {
        return this.getMyUnreadGroupNotifications
      } else return this.getMyReadGroupNotifications
    },
    async clickNotification(item) {
      if (item.ParentType && item.ParentType) {
        var payload = {
          objectType: item.ParentType,
          objectId: item.ParentGuid
        };
        var navigationObject = this.$store.getters.getDataObjectForIdAndObjectType(
          payload
        );
        if (isEmpty(navigationObject)) {
          this.$store.dispatch("showLoading");
          navigationObject = await this.$store.dispatch('fetchObject', {
            objectType: item.ParentType,
            objectGuid: item.ParentGuid,
            guidField: 'Guid'
          })
          this.$store.dispatch("hideLoading");
        }
        var payload = {
          splitCardId: item.ParentType + 's',
          object: navigationObject
        };
        this.$store.dispatch(SELECT_OBJECT, payload);
      }
      if (!isTrue(item.IsRead)) {
        this.markAsRead(item)
      }
      this.showMenu = false
    },
    markAsRead(item) {
      const oldObject = JSON.parse(JSON.stringify(item))
      let newItem = JSON.parse(JSON.stringify(item))
      newItem.IsRead = true
      var date = new Date();
      //"2011-12-19T15:28:46.493Z"
      newItem.DateRead = date.toISOString();
      var payload = {
        objectType: this.getViewConfig.objectType,
        object: newItem,
        oldObject
      };
      this.$store.dispatch("saveObjectForObjectType", payload);
    },
    markAsReadGroup(group) {
      if (group && group.notifications) {
        for (let index = 0, l = group.notifications.length; index < l; index++) {
          const notification = group.notifications[index];
          this.markAsRead(notification)
        }
      }
    },
    noItems(items) {

      return Object.keys(items).length === 0
    },
    getRelativeDate(item) {
      if (item && item.createdAt) {
        return moment(item.createdAt).fromNow()
      } return item.createdAt
    },
    openMenu() {
      if (this.getMyUnreadGroupNotifications.length > 0) {
        this.tab = 0
      }
    }
  }
};
</script>
