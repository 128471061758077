<template>
  <v-container
    ref="outerListContainerCard"
    class="pa-0 ma-0"
    fluid
  >
    <resize-observer @notify="onResizeComponent" />
    <filterView
      :viewconfig="viewconfig"
      :is-compact-view="isCompactView"
      :object="object"
      :on-search="onSearch"
      :search="searchField"
      :filter-items="currentItems"
      :refresh-data="refreshFunction"
    >
      <template v-slot:extra-filters>

        <v-select
          v-if="showGroupBy"
          v-model="groupBy"
          :items="getFields"
          :menu-props="{ bottom: true, offsetY: true }"
          item-text="value"
          item-value="value"
          class="text-field-transparent no-border"
          prepend-inner-icon="hi-bars-3-center-left"
          hide-details
          style="min-width: 150px;"
          dense
          label="Group by"
          solo
          clearable
          offset-y
          @change="onGroupBy"
        />



      </template>
    </filterView>
    <v-select
      v-if="showGroupByMobile"
      v-model="groupBy"
      :items="getFields"
      :menu-props="{ bottom: true, offsetY: true }"
      item-text="value"
      item-value="value"
      class="text-field-transparent no-border ma-1 mx-2"
      prepend-inner-icon="hi-bars-3-center-left"
      hide-details
      style="min-width: 150px;"
      dense
      label="Group by"
      solo
      clearable
      offset-y
      @change="onGroupBy"
    />
    <v-row
      class="pt-0 mt-0 mb-n1"
      style="min-height: 80px;"
    >
      <listView
        v-if="getFilterIsActive === true"
        ref="listView"
        :viewconfig="viewconfig"
        :selectionfunction="selectionfunction"
        :object="object"
        :height="getHeight"
        :search="getSearch"
        :group-by="groupBy"
        :list="list"
        :on-current-items="onCurrentItems"
        @onDoubleClick="onDoubleClicked"
      />
    </v-row>
  </v-container>
</template>

<script>
import * as Constants from "../../store/constants.js";
import * as UTIL from "../../api/utilities.js";
import filterView from "./FilterView";
import { CAN_EDIT } from "../../store/authorizationObjectContext";

export default {
  components: { filterView },
  props: {
    viewconfig: {
      type: String,
      default: ""
    },
    selectionfunction: {
      type: Function,
      default: null
    },
    object: {
      type: Object,
      default: null
    },
    height: {
      type: String,
      default: null
    },
    list: {
      type: Array,
      default: null
    },
    search: {
      type: String,
      default: ""
    },
    isCurrentTab: {
      type: Boolean,
      default: ""
    },
  },
  data() {
    return {
      fields: undefined,
      searchField: "",
      myListWidth: 0,
      showFilter: true,
      groupBy: null,
      currentItems: []
    };
  },
  computed: {
    /*
    getCurrentObject() {
      if (this.object !== null && this.object !== undefined){
        return this.object
      }
      return this.$store.getters.getCurrentObject;
    },
    */
    refreshFunction() {
      if (this.getCurrentIsOnline) {
        return this.refreshData
      } else return null
    },
    isEditMode() {
      return this.$store.getters.getEditMode(this.viewconfig);
    },
    getFieldListType() {
      if (this.isEditMode) {
        return "listEdit";
      }
      return "listDisplay";
    },
    getFields() {
      var fields = [];
      if (this.getViewConfig.subViewConfig != undefined) {
        var payload = {
          fieldList: this.getViewConfig.subViewConfig,
          size: 12,
          list: this.getFieldListType
        };
        // fields = this.$store.getters["getFieldsForViewFieldListAndSize"] (payload);
        fields = this.$store.getters.getFieldsForViewFieldListAndSize(payload);
      }
      fields.filter(element => element.type !== "Title");
      fields.sort(function (field1, field2) {
        if (field2.position > field1.position) {
          return -1;
        }
        if (field1.position > field2.position) {
          return 1;
        }
        return 0;
      });
      var field;
      for (var i = 0; i < fields.length; i++) {
        field = fields[i];
        if (i === 0) {
          field.align = "left";
        } else {
          field.align = "left"; //right
        }
        field.sortable = true;
      }
      // if (fields && Array.isArray(fields)) {
      //   fields.unshift({ value: '' })
      // }
      return fields;
    },
    getObjectType() {
      return this.getViewConfig.objectType;
    },
    getViewConfig() {
      return this.$store.getters.getViewConfigForID(this.viewconfig);
    },
    showGroupBy() {
      return !this.isCompactView && this.getOptionsGroupBy !== false
    },
    showGroupByMobile() {
      return this.isCompactView && this.getOptionsGroupBy !== false
    },
    getFilterIsActive() {
      return this.$store.getters.getFilterActivatedForViewConfig(
        this.viewconfig
      );
    },
    getCurrentFilter() {
      return this.$store.getters.getCurrentFilterForFilterId(this.getViewConfig.queryFilter)
    },
    getCurrentIsOnline() {
      console.log('getCurrentFilter', this.getCurrentFilter)
      return this.getCurrentFilter && UTIL.isTrue(this.getCurrentFilter.online)
    },
    getFilterIsActive() {
      return this.getCurrentFilter && !UTIL.isEmpty(this.getCurrentFilter.filterFields)
    },
    getHeight() {
      if (this.height !== null && this.height !== undefined) {
        var height = parseInt(this.height, 10);
        return height - 60 + "px";
      }
      return "600px";
    },
    getWidth() {
      if (this.myListWidth <= Constants.SMALL_SIZE_THRESHOLD) {
        return Constants.SMALL_SIZE;
      }
      if (
        Constants.SMALL_SIZE_THRESHOLD <= this.myListWidth &&
        this.myListWidth < Constants.MEDIUM_SIZE_THRESHOLD
      ) {
        return Constants.MEDIUM_SIZE;
      }
      if (
        Constants.MEDIUM_SIZE_THRESHOLD <= this.myListWidth &&
        this.myListWidth < Constants.BIG_SIZE_THRESHOLD
      ) {
        return Constants.BIG_SIZE;
      }
      return Constants.KING_SIZE;
    },
    isCompactView() {
      return this.getWidth === Constants.SMALL_SIZE;
    },
    getSearch() {
      if (!UTIL.isEmpty(this.searchField)) {
        return this.searchField;
      }
      return this.search;
    },
    isConfigMode() {
      return this.$store.getters.isConfigMode(this.getViewConfig);
    },
    getObjectConfig() {
      return this.$store.getters.getObjectConfigForType(
        this.getViewConfig.objectType
      );
    },
    getOptions() {
      if (this.getViewConfig && this.getViewConfig.options) {
        try {
          const options = JSON.parse(this.getViewConfig.options)
          return options
        } catch (error) {
          console.error(error)
          return null
        }
      }
      return null
    },
    getOptionsGroupBy() {
      if (this.getOptions && !UTIL.isEmpty(this.getOptions.groupBy)) {
        return this.getOptions.groupBy
      }
      return true
    },

  },

  /**
    We set the default filter for the viewConfig
   */
  mounted: function () {
    this.$nextTick(() => {
      this.onResizeComponent();
    });
    const savedState = this.$store.getters.getViewConfigState(this.viewconfig)
    if (savedState) {
      if (!UTIL.isEmpty(savedState.search)) {
        this.searchField = savedState.search
      }
      if (!UTIL.isEmpty(savedState.groupBy)) {
        this.groupBy = savedState.groupBy
      }
    }
  },
  watch: {
    isCurrentTab: function (newValue, oldValue) {
      if (oldValue === false && newValue === true) {
        if (this.viewconfig && this.getCurrentIsOnline && this.$refs.listView && this.$refs.listView.initLoaded) {
          this.refreshData();
        }
      }
    }
  },
  methods: {
    refreshData() {
      if (this.$refs && this.$refs.listView) {
        this.$refs.listView.fetchItems(true)
      }
    },
    onResizeComponent() {
      var container = this.$refs.outerListContainerCard;
      var width = 0;
      var height = 0;
      if (
        container !== undefined &&
        container !== null &&
        this.getViewConfig !== undefined &&
        this.getViewConfig !== null
      ) {
        width = container.clientWidth;
        height = container.clientHeight;

      }
      this.myListWidth = width;
      this.myListHeight = height;

    },
    onCurrentItems(items) {
      this.currentItems = items
      this.$store.dispatch('updateStateViewConfig', {
        viewConfig: this.viewconfig,
        fields: {
          items
        }
      })
    },
    canEditFromAuthorisations(action) {
      var payload = {
        objectType: this.getViewConfig.objectType,
        action: action,
        viewConfig: this.viewconfig,
        object: null
      };
      return this.$store.getters.userHasAuthorisation(payload);
    },
    onDoubleClicked() {
      if (!this.canEditFromAuthorisations(CAN_EDIT)) {
        return
      }

      if (!this.isEditMode) {
        this.operationId = UTIL.isEmpty(this.operationId) ? this.$store.getters.generateOperationId : this.operationId;

        var payload = {
          viewconfig: this.viewconfig,
          operationId: this.operationId
        };
        this.$store.commit("changeEditMode", payload);
        // if (this.isEditMode) {
        this.addToEdit(this.currentItems)
      }
    },
    addToEdit(items) {

      //Go to create operationContainers.
      // Retrieve the objects
      // this.operationId = this.operationId || this.$store.getters.getOperationIdForViewConfig(this.viewconfig)
      var objects = items;

      var objectPayload = {
        objects: objects,
        operationId: this.operationId,
        config: this.getObjectConfig,
        viewconfig: this.viewconfig,
      };
      this.$store.dispatch(
        "createOperationContainersForObjects",
        objectPayload
      );

    },

    getClassOfFilters(type) {
      if (type === "spacer") {
        if (this.getWidth === Constants.SMALL_SIZE) {
          return "xs0";
        } else {
          return "";
        }
      }
      if (this.getWidth === Constants.SMALL_SIZE || this.getFilterIsActive) {
        return "xs12";
      }
      if (this.getWidth === Constants.MEDIUM_SIZE) {
        return "xs5";
      }
      if (this.getWidth === Constants.BIG_SIZE) {
        return "xs4";
      }
      return "xs3";
    },
    onSearch(value) {
      this.searchField = value;
      this.$store.dispatch('updateStateViewConfig', {
        viewConfig: this.viewconfig,
        fields: {
          search: value
        }
      })
    },
    onGroupBy(value) {
      this.$store.dispatch('updateStateViewConfig', {
        viewConfig: this.viewconfig,
        fields: {
          groupBy: value
        }
      })
    },

  }
};
</script>

<style>
.removeScroll {
  overflow-y: no;
  overflow-x: no;
}
</style>
